import * as React from "react"
import Layout from "../components/layout"
import Carousel from "react-multi-carousel"
import Card from "../components/card"
import { graphql, Link } from "gatsby"
import Date from "../components/date"
import { getImage } from "gatsby-plugin-image"
import { Helmet } from "react-helmet"
//images
import Image from "react-bootstrap/Image"
import iphone from "../images/iphone.png"
import Main1 from "../images/img-1.png"
import Main2 from "../images/img-2.png"
import GooglePlay from "../images/googleplay-button.png"
import AppStore from "../images/appstore-button.png"
import Map1 from "../images/maps-netherlands.png"
import Map2 from "../images/maps-turkey.png"

export const query = graphql`
  query POSTS_INDEX_QUERY {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { published: { eq: true } } }
    ) {
      nodes {
        id
        excerpt(pruneLength: 250)
        fields {
          slug
        }
        frontmatter {
          title
          date
          cover {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
    }
  }
`;

const IndexPage = ({ data }) => {
  return (
    <div id="background">
      <Helmet>
          <meta charSet="utf-8" />
          <title>Didilabs</title>
        </Helmet>
      <Layout>
        <section>
          <div className="container">
            <div className="row mt-5">
              <div id="main-title" className="col-6">
                <div className="d-flex h-100 flex-column justify-content-center">
                  <h3 className="display-6 fw-bold text-light ">
                    Didilabs mobile application development company with offices
                    in Amsterdam and Istanbul.
                  </h3>
                  <p className="lead mt-3">
                    We build entertaining mobile applications reaching millions
                    of users globally everyday. Our mission is to spread
                    happiness by creating unique apps that offer easily
                    accessible fortune readings for everyone.
                  </p>
                </div>
              </div>
              <div className="col-6">
                <Image id="mobile" src={iphone} />
              </div>
            </div>
          </div>
        </section>

        <section id="news" style={{ marginTop: "20rem" }}>
          <h3 className="text-center display-4 fw-bold">
            LATEST NEWS & ARTICLES
          </h3>
          <div className="container mt-5">
            <Carousel
              additionalTransfrom={0}
              arrows
              autoPlay
              autoPlaySpeed={5000}
              centerMode={false}
              focusOnSelect={false}
              removeArrowOnDeviceType={["tablet", "mobile"]}
              infinite
              itemClass=""
              keyBoardControl
              minimumTouchDrag={80}
              renderButtonGroupOutside={false}
              renderDotsOutside={false}
              showDots={false}
              responsive={{
                desktop: {
                  breakpoint: {
                    max: 3000,
                    min: 1024,
                  },
                  items: 3,
                  partialVisibilityGutter: 40,
                },
                mobile: {
                  breakpoint: {
                    max: 464,
                    min: 0,
                  },
                  items: 1,
                  partialVisibilityGutter: 30,
                },
                tablet: {
                  breakpoint: {
                    max: 1024,
                    min: 464,
                  },
                  items: 2,
                  partialVisibilityGutter: 30,
                },
              }}
            >
              {data.allMdx.nodes.map(
                ({ slug, excerpt, frontmatter, fields }) => {
                  const cardImage = getImage(frontmatter.cover);

                  return (
                    <Card
                      key={fields.slug}
                      cardTitle={frontmatter.title}
                      cardText={excerpt}
                      cardTime={<Date time={frontmatter.date} />}
                      image={cardImage}
                      link={fields.slug}
                    />
                  );
                }
              )}
            </Carousel>
          </div>
        </section>

        <section id="works" style={{ marginTop: "15rem" }}>
          <div className="container">
            <h3 className="text-center display-4 fw-bold">OUR WORKS</h3>
            <div className="row" style={{ marginTop: "10rem" }}>
              <div className="col-md-6">
                <Image src={Main1} className="featurette-image img-fluid mx-auto" />
              </div>
              <div className="col-md-6">
                <div className="d-flex h-100 flex-column justify-content-center">
                  <h2
                    className="fw-bold gold-color"
                    style={{ letterSpacing: ".5rem" }}
                  >
                    KAAVE APP
                  </h2>
                  <p className="lead" style={{ fontSize: "20px" }}>
                    A super entertainment app that combines the mysterious world of fortune telling with fun games.
                    Loaded with many fortune telling options such as coffee cup readings, angel cards, astrology and tarot (soon!),
                    Kaave is used by five million monthly unique users all around the world.
                  </p>
                  <div className="row">
                    <div className="col">
                      <Link to="https://play.google.com/store/apps/details?id=com.didilabs.kaavefali&hl=en" target="_blank">
                        <Image src={GooglePlay} fluid />
                      </Link>
                    </div>
                    <div className="col">
                      <Link to="https://itunes.apple.com/us/app/kaavefali/id505658864?l=en" target="_blank">
                        <Image src={AppStore} fluid />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section style={{ marginTop: "25rem" }}>
          <div className="container">
            <div className="row mt-5">
              <div className="col-md-6 order-1">
                <div className="d-flex h-100 flex-column justify-content-center">
                  <h3
                    className="fw-bold gold-color"
                    style={{ letterSpacing: ".5rem" }}
                  >
                    MISTIK TAROT
                  </h3>
                  <p className="lead" style={{ fontSize: "20px" }}>
                    First ever tarot reading app that uses an advanced artificial intellligence.
                    Including unique and original tarot decks, this app will bring fun to your daily tarot readings.
                  </p>
  
                      <Link to="https://apps.apple.com/app/mistiktarot/id727319607" target="_blank">
                        <Image src={AppStore} fluid />
                      </Link>
                </div>
              </div>
              <div className="col-md-6 order-2">
                <Image src={Main2} fluid />
              </div>
            </div>
          </div>
        </section>

        <section id="contact" style={{ marginTop: "40rem" }}>
          <div className="container">
            <h3 className="text-center display-4 fw-bold">CONTACT US</h3>
            <div className="row" style={{ marginTop: "10%" }}>
              <div className="col-md-7">
                <Image src={Map1} fluid />
              </div>
              <div className="col-1"></div>
              <div className="col-md-4 map-text">
                <div className="d-flex h-100 flex-column justify-content-center">
                  <h3
                    className="fw-bold gold-color"
                    style={{ letterSpacing: ".5rem" }}
                  >
                    NETHERLANDS
                  </h3>
                  <h6 className="mt-3 gold-color">ADDRESS:</h6>
                  <p className="fw-bold fs-3">
                    Postbus 10552, 1001 EN, Amsterdam
                  </p>
                  <h6 className="mt-5 gold-color">E-MAIL:</h6>
                  <p className="fw-bold fs-3">info@didilabs.com</p>
                </div>
              </div>
            </div>
            <div className="row" style={{ marginTop: "15%" }}>
              <div className="col-md-4 map-text order-1">
                <div className="d-flex h-100 flex-column justify-content-center">
                  <h3
                    className="fw-bold gold-color"
                    style={{ letterSpacing: ".5rem" }}
                  >
                    TURKEY
                  </h3>
                  <h6 className="mt-3 gold-color">ADDRESS:</h6>
                  <p className="fw-bold fs-3">
                    Büyükdere Cad. Nurol Plaza Sitesi No:255/B02 Maslak 34485
                  </p>
                  <h6 className="mt-5 gold-color">E-MAIL:</h6>
                  <p className="fw-bold fs-3">info@didilabs.com</p>
                </div>
              </div>
              <div className="col-1"></div>
              <div className="col-md-7 order-2">
                <Image src={Map2} fluid />
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </div>
  );
};

export default IndexPage;
